/* src/Pages/Blocked/Blocked.css */
.blocked-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center vertically */
  text-align: center;
  background-color: #000000; /* Light background color */
}

.blocked-container h1 {
  font-size: 3rem; /* Large font size */
  color: #ff0000; /* Red color for emphasis */
  font-weight: bold; /* Bold text */
  padding: 20px;
  border: 2px solid #ff0000; /* Optional border for emphasis */
  border-radius: 10px; /* Rounded corners */
  background-color: #fff; /* White background for the text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}
