#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mitr", sans-serif;
  overflow-x: hidden;
  background-color: black; /* Added this line */
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}
img {
  max-width: 100%;
}
.h-50 {
  height: 50px !important;
}

.primary-text {
  color: #000 !important;
}

.danger-text {
  color: #fd5c46 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

h2 {
  font-size: 60px;
  font-weight: 500;
  line-height: 107px;
}
p {
  font-size: 20px;
  line-height: 29px;
  font-weight: normal;
}

/* Header  */

.navbar {
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 11;
  /* background: black; */
}

.navbar .nav-link {
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
  margin: 5px;
  padding: 5px 12px !important;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  color: black;
  background: #fada4a;
  transition: all 0.2s ease-in-out;
}
.navbar-nav.mx-auto {
  background: black;
  border-radius: 10px;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

.h-40 {
  height: 40px !important;
}

/* Banner */
.banner {
  background-image: url(../public/assets/bg1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
}

.banner h1 {
  color: #000;
  font-size: 100px;
  font-weight: 500;
  line-height: 107px;
  text-transform: uppercase;
}

.banner p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.top-text {
  color: #000;
  font-size: 44px;
  font-weight: 500;
  line-height: 107px;
}
.panda-img img {
  max-width: 300px;
  width: 100%;
}

.primary-btn {
  height: 46px;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 14px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.primary-btn:hover {
  background: #fada4a;
  color: #000;
  border-color: #fada4a;
  transition: all 0.1s ease-in-out;
}

.primary-btn-outline {
  background: white;
  border-radius: 10px;
  display: inline-flex;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: black;
  padding: 14px 25px;
  border: 1px solid #fff;
  height: 46px;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.primary-btn-outline:hover {
  background: transparent;
  color: #fff;
  transition: all 0.1s ease-in-out;
}
.primary-secondary {
  height: 46px;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  color: #000;
  background: #fada4a;
  border: 1px solid #fada4a;
  border-radius: 10px;
  padding: 14px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.primary-secondary:hover {
  color: #fada4a;
  background: black;
  border-color: black;
  transition: all 0.1s ease-in-out;
}

.learn-btn {
  height: 46px;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  color: #fff;
  background: #38a0eb;
  border: 1px solid #38a0eb;
  border-radius: 10px;
  padding: 14px 25px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.learn-btn:hover {
  background: transparent;
  color: #38a0eb;
  transition: all 0.1s ease-in-out;
}
.learn-btn svg path {
  fill: #fff;
  transition: all 0.1s ease-in-out;
}
.learn-btn:hover svg path {
  fill: #38a0eb;
  transition: all 0.1s ease-in-out;
}

.transition-all {
  transition-duration: 0.15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.z-1 {
  z-index: 1;
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }
}

@media (max-width: 767px) {
  .navbar {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .left-aside,
  .right-aside {
    margin-top: 45px;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
  }
  .navbar-nav.mx-auto {
    background: transparent;
    border-radius: 10px;
    display: inline-flex;
    margin-left: 20px !important;
    padding-left: 0px !important;
  }

  .navbar-toggler {
    color: #000;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar .nav-link,
  .navbar .nav-link.active {
    color: black;
    background: #fada4a;
    transition: all 0.2s ease-in-out;
  }
}

.bg2 {
  background-color: black; /* Ensures background behind the image is black */
  background-image: url(../public/assets/bg2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
}

.bg3 {
  background-image: url(../public/assets/bg3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  padding-bottom: 400px;
}

.bg4 {
  background-color: black;
  background-image: url(../public/assets/grass.png);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px 0 250px 0;
}

.partner-box {
  background: #292929;
  border-radius: 20px;
  padding: 40px 25px;
  max-width: 300px;
  width: 300px;
  height: 140px;
  display: inline-flex;
}

.partner-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  text-align: center;
}
.rfm-initial-child-container,
.rfm-marquee,
.rfm-marquee-container {
  gap: 20px !important;
}

/* footer  */

footer {
  background-color: #303030;
  background-position: right;
  background-size: 500px;
  background-repeat: no-repeat;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 20px 0 2px 0;
  margin-top: -80px;
}

.footer-link {
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
  border-radius: 20px;
  border: 1px solid black;
  background: #f9fecd;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  transform: translate(0, 0);
  transition: all 0.15s linear;
}

.footer-link:hover {
  transform: translate(0, -5px);
  transition: all 0.15s linear;
}

.navbar-brand img,
.footer-logo img {
  height: 50px;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

@media (max-width: 767px) {
  .banner h1,
  h2 {
    font-size: 50px;
    line-height: 57px;
  }
  .top-text {
    font-size: 24px;
    line-height: 30px;
  }
  .top-text.pt-100 {
    padding-top: 80px;
  }
  .banner {
    height: auto;
    min-height: auto;
    background-image: url(../public/assets/bg1-sm.png);
    background-size: cover;
    padding-bottom: 410px;
  }
  .primary-btn,
  .primary-secondary,
  .primary-btn-outline {
    padding: 8px 20px;
    line-height: 20px;
    font-size: 14px;
    height: auto;
    display: inline-flex;
  }
  .panda-img img {
    max-width: 200px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .logo-img {
    height: 50px;
  }
  .fs-40 {
    font-size: 40px !important;
  }

  .bg2 {
    background-image: url(../public/assets/bg2-sm.png);
    background-position: center;
    background-size: cover;
    height: auto;
    padding-bottom: 410px;
  }
  .bg3 {
    background-image: url(../public/assets/bg3-sm.png);
    background-position: center;
    background-size: cover;
    height: auto;
    padding-bottom: 410px;
  }
  .partner-box {
    border-radius: 16px;
    padding: 25px;
    max-width: 300px;
    width: 225px;
    height: 100px;
  }
  .bg4 {
    background-position: bottom right;
    background-size: 190% 35%;
    padding-bottom: 200px;
  }
  footer {
    padding-bottom: 150px;
    background-position: bottom right;
    background-size: 400px;
    margin-top: -40px;
  }
  .navbar-brand img,
  .footer-logo img {
    height: 40px;
  }
}
